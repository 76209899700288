// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_API_URL: 'https://dev.api.itam.wginb.com',
  ASSET_CATEGORY_API_URL : 'https://dev.api.itam.wginb.com/itam',
  BULK_ASSET_CATEGORY_API_URL: 'https://dev.api.itam.wginb.com/itam/category/bulk',
  ASSET_ITEM_TYPE_API_URL : 'https://dev.api.itam.wginb.com/itam',
  BULK_ASSET_ITEM_TYPE_API_URL : 'https://dev.api.itam.wginb.com/itam/itemtype/bulk',
  ASSET_ITEM_API_URL : 'https://dev.api.itam.wginb.com/itam',
  BULK_ASSET_ITEM_API_URL : 'https://dev.api.itam.wginb.com/itam/item/bulk',
  AUTHENTICATION_API_URL : 'https://dev.api.itam.wginb.com/itamlogin',
  DELETE_CATEGORY_API : 'https://dev.api.itam.wginb.com/itam/category/remove/',
  DELETE_ITEM_TYPE_API : 'https://dev.api.itam.wginb.com/itam/itemtype/remove/',
  DELETE_ITEM_API : 'https://dev.api.itam.wginb.com/itam/item/remove/',
  STATIC_API_URL: 'https://dev.api.itam.wginb.com/itam',
  RESET_PASSWORD: "https://dev.portal.reset.wginb.com",
  MSAL_CONFIG: {
    clientId:"f529d809-fdd8-4726-9659-2fc6c6f8f51d",
    authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
    redirectUri: "https://dev.api.itam.wginb.com",
    postLogoutRedirectUri: "https://dev.api.itam.wginb.com"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
