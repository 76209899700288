import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import jwt_decode from 'jwt-decode';

import { Session } from '../models/authentication/auth.model';


@Injectable({ providedIn: 'root' })

export class SessionService {

    authStatus = new BehaviorSubject(false);

    constructor() { }

    updateAuthStatus(status: boolean): void {
        this.authStatus.next(status);
    }

    setSessionObject(sessionData: Session): void {
        sessionStorage.setItem('itam-user-session', JSON.stringify(sessionData));
        this.authStatus.next(true);
    }

    getSessionData(value: string): string {
        const session: Session = JSON.parse(sessionStorage.getItem('itam-user-session'));
        return session[value];
    }

    getTokenData(value: string): string {
        const session: Session = JSON.parse(sessionStorage.getItem('itam-user-session'));
        const tokenData: Session = jwt_decode(session.idtoken);
        return tokenData[value];
    }

    isLoggedIn(): boolean {

        const session: Session = JSON.parse(sessionStorage.getItem('itam-user-session'));
        if (session === null) {
            return false;
        } else {
            const timeExpired = new Date().getTime() > (parseInt(this.getTokenData('exp'), 10) * 1000);
            return !timeExpired;
        }
    }

    clearSession(): void {
        sessionStorage.removeItem('itam-user-session');
        this.authStatus.next(false);
    }

}
