import { Component, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';

import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  message = 'Unknown Error Occured';
  body = '';
  toastClass = 'error';

  constructor(private toastService: ToastService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.toastService.toastMessage.subscribe((state: any) => {
      this.messageService.add({ severity: state.code, summary: state.message, detail: state.body });
    });
  }
}
