import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/auth-guards/auth.guard';
import { AuthorizedUserGuard } from './shared/auth-guards/authorized-user.guard';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module')
    .then((module) => module.DashboardModule), canActivate: [AuthorizedUserGuard] },
  { path: 'asset-mgmt', loadChildren: () => import('./asset-management/asset-management.module')
    .then((module) => module.AssetManagementModule), canActivate: [AuthorizedUserGuard] },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
