import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SessionService } from './shared/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'itam';
  authStatus = false;

  authSub: Subscription;

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.authSub = this.sessionService.authStatus.subscribe((status) => {
      this.setAuthStatus();
    });
  }

  setAuthStatus(): void {
    this.authStatus = this.sessionService.isLoggedIn();
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
  }

}
