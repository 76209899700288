import { NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';

import { AuthenticationService } from '../shared/services/authentication.service';
import { SessionService } from '../shared/services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  authStatus = false;
  userName = '';

  navBarItems: MenuItem[];
  activeNavBarItem: MenuItem;

  menuItems: MenuItem[];
  activeMenuItem: MenuItem;

  constructor(private sessionService: SessionService, private router: Router,
              private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authStatus = this.sessionService.isLoggedIn();
    this.sessionService.authStatus.subscribe((status) => {
      this.authStatus = this.sessionService.isLoggedIn();
      if (this.authStatus) {
        this.userName = this.sessionService.getTokenData('name');
      }
    });
    this.navBarItems = [
      {label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: './dashboard/admin'},
      {label: 'Asset Management', icon: 'pi pi-fw pi-calendar', routerLink: './asset-mgmt'}
    ];
    this.activeNavBarItem = this.navBarItems[0];

    this.menuItems = [
      {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: () => {
        this.authService.logout();
      }},
    ];
  }

  onLogout(): void {
    this.sessionService.clearSession();
    this.router.navigate(['/auth/login']);
  }


}
