import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionService } from '../services/session.service';
import { InterceptorSkip } from '../services/authentication.service';
import { SpinnerService } from '../components/spinner/spinner.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService, private spinnerService: SpinnerService ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinnerService.showSpinner();
    if (request.headers && request.headers.has(InterceptorSkip)) {
      const headers = request.headers.delete(InterceptorSkip);
      request = request.clone({ headers });
    } else {
      const idtoken = this.sessionService.getSessionData('idtoken');
      request = request.clone({
        headers: request.headers.set('Authorization', idtoken)
      });
    }

    const localEnvironment = window.location.href.indexOf('.com') === -1;
    const serverlessApi = this.isServerlessApi(request.url);

    if (localEnvironment && serverlessApi) {
      request = this.applyCorsPolicy(request);
    }
    return next.handle(request);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/api./.test(url)) {
      return true;
    }
    return false;
  }

  private applyCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/api/.test(req.url)) {
      return req.clone({
        url: req.url.replace( environment.BASE_API_URL , ''),
      });
    }
    return req;
  }

}
