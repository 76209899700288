import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class SpinnerService {

    spinnerState = new BehaviorSubject(false);

    showSpinner(): void {
        this.spinnerState.next(true);
    }

    hideSpinner(): void {
        this.spinnerState.next(false);
    }
}
