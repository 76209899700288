import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ToastService {

    toastMessage = new BehaviorSubject({
        message: '',
        code: '',
        body: ''
    });

    showMessage(message: string, code: string, body: string = ''): void {
        this.toastMessage.next({
            message,
            code,
            body
        });
    }

    resetToastState(): void {
        this.toastMessage.next({
            message: '',
            code: '',
            body: ''
        });
    }
}
