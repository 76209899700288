import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ToastComponent } from './shared/components/toast/toast.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { ToastService } from './shared/components/toast/toast.service';
import { environment } from 'src/environments/environment';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

const msalConfig = environment.MSAL_CONFIG;
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ToastComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MenubarModule,
    TabMenuModule,
    MenuModule,
    SharedModule,
    RouterModule,
    ProgressSpinnerModule,
    ToastModule,
    MsalModule.forRoot(
        new PublicClientApplication({
          auth: {
            clientId: msalConfig.clientId,
            authority: msalConfig.authority,
            redirectUri: msalConfig.redirectUri,
            postLogoutRedirectUri: msalConfig.postLogoutRedirectUri
          },
          cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: isIE
          }
        }),
        {
          interactionType: InteractionType.Redirect,
          authRequest: {
            scopes: ["user.read"]
          }
        },
        {
          interactionType: InteractionType.Redirect,
          protectedResourceMap: new Map(
            [
              ["https://graph.microsoft.com/v1.0/me", ["user.read"]]
            ]
          )
        }
      )
  
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  DatePipe, ConfirmationService, MessageService, ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
