<div class="header">
    <div class="flex">
        <img src="/assets/images/wg-logo-white.svg" height="38" class="my-2 mx-3">
        <div class="flex justify-content-center" *ngIf="authStatus">
            <a class="navitem" routerLink="/dashboard/admin" routerLinkActive="navitem-active">
                Dashboard
            </a>
            <a class="navitem" routerLink="/asset-mgmt" routerLinkActive="navitem-active">
                Asset Management
            </a>
        </div>
    </div>
    <div class="header-menu">
        <div class="flex" *ngIf="authStatus">
            <p class="px-2 my-3">{{ userName | titlecase }} </p>
            <i class="my-3 pi pi-angle-down fs-5" (click)="menu.toggle($event)"></i>
        </div>
        <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
</div>